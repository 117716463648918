export interface Data {
  name: string;
  percent: string;
  address: string;
  logo1: string;
  logo2: string;
}

const maindata: Data[] = [
  {
    name: "UNI/ETH",
    percent: "0.3",
    address: "0x7b47951986ebb66e5eb9d72b0f389f4ebb458e1a",
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "USDT/ETH",
    percent: "0.3",
    address: "0x0d2df9efa55b8b619282b32d4cc6192f38f50fff",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "DAI/ETH",
    percent: "0.3",
    address: "0xc2efad3800d58c379a5131627f3864f42c82af2e",
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "ETH/USDC",
    percent: "0.3",
    address: "0x8343fbf24ddb51583f5c260761ab8662221de6cc",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "ETH/WBTC",
    percent: "0.3",
    address: "0x6cc805c617232ccb21166ff4c2b3f7431d3ff0d5",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    name: "USDT/USDC",
    percent: "1",
    address: "0x49ae3ef59dd699f843985ea7859ff2f5eb16d5ee",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "USDT/UNI",
    percent: "0.3",
    address: "0xdc314ce190004918d561132be90df5bf1c6da1ac",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
  },
  {
    name: "USDT/WBTC",
    percent: "0.3",
    address: "0xcf5cdf1fb69672a86971c1f4b1d30c9e72119903",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    name: "DAI/USDT",
    percent: "0.05",
    address: "0x3204fa973fef6b110249df6fd5f6d6f436005d88",
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "PILOT/ETH",
    percent: "0.05",
    address: "0x2d1491964d47aeebd14d9e9a4bb1b320d8716904",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
];

export default maindata;
