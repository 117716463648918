import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { subgraphRequest } from "../../utils/helpers";
import { CTooltip } from "..";
import { ogTrunc, trunc } from "../../utils/formating";
import { params, poolParams } from "./queries";
import ErrorIcon from "@material-ui/icons/Error";
import { checkBlackList } from "../../utils/helpers";

import { Dialog } from "..";

import SearchBar from "material-ui-search-bar";
import {
  Button,
  Grid,
  CircularProgress,
  Container,
  Typography,
  useTheme,
  Tooltip,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import Empty from "../emptyState/empty";

export interface DataObj {
  address: string;
  name: string;
}

export interface Data {
  id: string;
  rebaseCount: string;
  txCount: string;
  totalCollectedAmount: string;
  totalDepositedAmount: string;
  totalWithdrawnAmount: string;
  poolCreatedCount: string;
}

interface IData {
  id: string;
  totalPilotCollectedInFee: string;
  totalDepositedAmount: string;
  totalCollectedAmount: string;
  totalIndexAmount: string;
  totalWithdrawnAmount: string;
  txCount: string;
  user: IUser;
}

interface IUser {
  id: string;
  rebaseCount: string;
  poolCreatedCount: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  title: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Address",
    title: "Hello world",
  },
  {
    id: "rebaseCount",
    numeric: false,
    disablePadding: false,
    label: "Pilot Fees", // Rebase here
    title: "Fees collected in Pilot token",
  },
  {
    id: "totalCollectedAmount",
    numeric: false,
    disablePadding: false,
    label: "Native Fees",
    title: "Fees collected in native tokens",
  },
  {
    id: "totalDepositedAmount",
    numeric: false,
    disablePadding: false,
    label: "Liquidity",
    title: "Liquidity added by user",
  },
  {
    id: "totalWithdrawnAmount",
    numeric: false,
    disablePadding: false,
    label: "Pilot Burned", //Withdraw
    title: "Pilot burned by user",
  },
  {
    id: "poolCreatedCount",
    numeric: false,
    disablePadding: false,
    label: "Vaults Created",
    title: "Pools created by user",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  addressObj: DataObj;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">S.No.</TableCell>
        {headCells.map(
          (headCell) =>
            (props.addressObj.address === "" ||
              (headCell.id !== "totalWithdrawnAmount" &&
                headCell.id !== "poolCreatedCount")) && (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                className={classes.tooltip}
              >
                {headCell.id !== "id" ? (
                  <Box className={classes.box}>
                    <Typography>
                      <CTooltip title={headCell.title}>
                        <ErrorIcon style={{ fontSize: "0.8rem" }} />
                      </CTooltip>
                    </Typography>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label === "Vaults Created" && sm_
                        ? "Vaults"
                        : headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </Box>
                ) : (
                  <Box>{headCell.label}</Box>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("desc");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [addressObj, setAddress] = useState<DataObj>({ address: "", name: "" });
  const [rows, setRows] = useState<Data[]>([]);
  const [tableRows, setTableRows] = useState<Data[]>(rows);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    addressObj.address ? "totalCollectedAmount" : "rebaseCount"
  );
  const [searched, setSearched] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  const getPoolData = async (address: string) => {
    setLoading(true);
    if (address !== "") {
      //@ts-ignore
      poolParams.userPoolInfos.__args = {
        where: {
          pool: address,
          // pool: "0xbabe273953fe000e69a864fce281a6dd8276924c",
        },
      };
      const data = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/rafaqat11/lmgr2",
        poolParams
      );
      // console.log("hahaha data here: ", data)
      if (data && data.userPoolInfos.length > 0) {
        const test: Data[] = data.userPoolInfos
          .filter((row: IData) => checkBlackList(row.user.id))
          .map((row: IData) => {
            const test2: Data = {
              id: row.user.id,
              rebaseCount: row.totalPilotCollectedInFee,
              totalCollectedAmount: row.totalCollectedAmount,
              totalDepositedAmount: row.totalDepositedAmount,
              totalWithdrawnAmount: row.totalWithdrawnAmount,
              poolCreatedCount: row.user.poolCreatedCount,
              txCount: row.txCount,
            };
            return test2;
          });
        setRows(test);
        setTableRows(test);
        setPage(0);
        setLoading(false);
      } else {
        setRows([]);
        setTableRows([]);
        setLoading(false);
      }
      // setRows([]);
      // setTableRows([]);
      // setPage(0);
      // setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    const dataRow = await subgraphRequest(
      "https://api.thegraph.com/subgraphs/name/rafaqat11/lmgr2",
      params
    );

    const checkData = dataRow.userEs
      .filter((row: any) => checkBlackList(row.id))
      .map((row: any) => {
        const value: Data = {
          id: row.id,
          rebaseCount: row.totalPilotCollectedInFee,
          totalCollectedAmount: row.totalCollectedAmount,
          totalDepositedAmount: row.totalDepositedAmount,
          totalWithdrawnAmount: row.totalPilotBurnFromIndexFund,
          poolCreatedCount: row.poolCreatedCount,
          txCount: row.txCount,
        };
        return value;
      });

    setRows(checkData);
    setTableRows(checkData);
    setPage(0);
    setLoading(false);
  };

  useEffect(() => {
    if (addressObj.address !== "") {
      getPoolData(addressObj.address);
    } else {
      getData();
    }
  }, [addressObj.address]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log(newPage);
  };

  const setItem = (value: DataObj) => {
    setAddress(value);
    setOpen(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = rows.filter((row) => {
      return row.id.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTableRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const CustomizedSelects = () => {
    return (
      <div className={classes.selectItem}>
        <Button
          style={{ minWidth: xs_ ? 0 : 200 }}
          onClick={handleOpen}
          endIcon={<ArrowDropDownIcon style={{ justifyContent: "flex-end" }} />}
        >
          {addressObj.name === "" || addressObj.name === "Show All Pools" ? (
            <Typography style={{ textTransform: "capitalize" }}>
              Select Pair
            </Typography>
          ) : (
            addressObj.name
          )}
        </Button>
      </div>
    );
  };

  const getConvertedTable = (tableRows: Data[]) => {
    const updatedData = tableRows.map((row: Data) => {
      return {
        ...row,
        rebaseCount: parseFloat(row.rebaseCount),
        txCount: parseFloat(row.txCount),
        totalCollectedAmount: parseFloat(row.totalCollectedAmount),
        totalDepositedAmount: parseFloat(row.totalDepositedAmount),
        totalWithdrawnAmount: parseFloat(row.totalWithdrawnAmount),
        poolCreatedCount: parseFloat(row.poolCreatedCount),
      };
    });
    return updatedData;
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} close={() => setOpen(!open)} setItem={setItem} />
      <Paper className={classes.paper}>
        <Grid container className={classes.wrap}>
          <Grid item md={8} sm={12} xs={12}>
            <div className={classes.bar}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                style={{
                  marginLeft: "10px",
                  background: "transparent",
                  border: "1px solid rgba(255,255,255,0.1)",
                  color: "#EFA618",
                }}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Grid
              container
              alignItems="stretch"
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item style={{ marginRight: "10px" }}>
                {CustomizedSelects()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              addressObj={addressObj}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell rowSpan={3} colSpan={sm_ ? 4 : 7}>
                    <Container className={classes.boxContainer}>
                      <CircularProgress size={50} />
                    </Container>
                  </TableCell>
                </TableRow>
              ) : tableRows.length === 0 && !loading ? (
                <TableRow>
                  <TableCell rowSpan={3} colSpan={sm_ ? 4 : 7}>
                    <Box style={{ minHeight: !sm_ ? "470px" : "0" }}>
                      <Empty />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(
                  getConvertedTable(tableRows),
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="center">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">
                          {ogTrunc(row.rebaseCount) > 0 ? (
                            <Tooltip
                              arrow
                              placement="left"
                              title={row.rebaseCount}
                            >
                              <Typography>{trunc(row.rebaseCount)}</Typography>
                            </Tooltip>
                          ) : (
                            <Typography>{trunc(row.rebaseCount)}</Typography>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {ogTrunc(row.totalCollectedAmount) > 0 ? (
                            <Tooltip
                              arrow
                              placement="left"
                              title={row.totalCollectedAmount}
                            >
                              <Typography>
                                {trunc(row.totalCollectedAmount)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              {trunc(row.totalCollectedAmount)}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {ogTrunc(row.totalDepositedAmount) > 0 ? (
                            <Tooltip
                              arrow
                              placement="left"
                              title={row.totalDepositedAmount}
                            >
                              <Typography>
                                {trunc(row.totalDepositedAmount)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              {trunc(row.totalDepositedAmount)}
                            </Typography>
                          )}
                        </TableCell>
                        {!addressObj.address && (
                          <TableCell align="left">
                            {ogTrunc(row.totalWithdrawnAmount) > 0 ? (
                              <Tooltip
                                arrow
                                placement="left"
                                title={row.totalWithdrawnAmount}
                              >
                                <Typography>
                                  {trunc(row.totalWithdrawnAmount)}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>
                                {trunc(row.totalWithdrawnAmount)}
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        {!addressObj.address && (
                          <TableCell align="left">
                            {row.poolCreatedCount}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
              )}
              {emptyRows > 0 && tableRows.length > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} style={{ borderBottom: "none" }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {tableRows.length > rowsPerPage ? (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        ) : null}
      </Paper>
    </div>
  );
}
