let params = {
  userEs: {
    id: true,
    txCount: true,
    rebaseCount: true,
    poolCreatedCount: true,
    totalDepositedAmount: true,
    totalCollectedAmount: true,
    totalWithdrawnAmount: true,
    totalPilotCollectedInFee: true,
    totalPilotBurnFromIndexFund: true,

    userPoolInfos: {
      id: true,
      txCount: true,
      totalDepositedLiquidity: true,
      totalDepositedAmount: true,
      totalCollectedAmount: true,
      totalIndexAmount: true,
      totalWithdrawnAmount: true,
    },
  },
};

let poolParams = {
  userPoolInfos: {
    id: true,
    txCount: true,
    totalDepositedLiquidity: true,
    totalDepositedAmount: true,
    totalCollectedAmount: true,
    totalIndexAmount: true,
    totalWithdrawnAmount: true,
    totalPilotCollectedInFee: true,
    user: {
      id: true,
      rebaseCount: true,
      poolCreatedCount: true,
    },
  },
};

export { params, poolParams };
