import { useState, useMemo, useEffect } from "react";
import {
  Dialog as MuiDialog,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Grid,
  Input,
  Divider,
  ListItemAvatar,
  ListItemText,
  Button,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { GreenInfo } from "../";
import { DataObj } from "../table/index";

import maindata, { Data } from "./DialogData";

import { DoubleToken } from "../";
import useStyles from "./styles";
import { CloseButton, Header } from "../index";
import SearchBar from "material-ui-search-bar";
import Empty from "../emptyState/empty";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <>{value === index && children}</>
);

interface IDialog {
  open: boolean;
  close: () => void;
  setItem: (value: DataObj) => void;
}

const Dialog: React.FC<IDialog> = ({ open, close, setItem }) => {
  const classes = useStyles();
  const [address, setAddress] = useState<string>("");
  const [data, setData] = useState<Data[]>([...maindata]);

  const [nav, setNav] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const requestSearch = (searchedVal: string) => {
    const filteredRows = maindata.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const handleClose = (): void => {
    setSearch("");
    close();
    // setInterval(() => {
    //   setData(maindata);
    // }, 1000);
    // clearInterval();
  };

  useEffect(() => {
    if (open) setData(maindata);
  }, [open]);

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth="xl"
    >
      <TabPanel value={nav} index={0}>
        <Container className={classes.container} maxWidth="xs">
          <Box className={classes.box}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Select Pair</Typography>
              </Grid>
              <Grid item>
                <CloseButton onClick={handleClose} />
              </Grid>
            </Grid>
            <SearchBar
              className={classes.searchBar}
              placeholder="Search by token name"
              value={search}
              onChange={(searchVal) => requestSearch(searchVal)}
              // onCancelSearch={() => cancelSearch()}
              style={{
                background: "#000",
                color: "#EFA618",
                border: "1px solid rgba(255,255,255,0.15)",
              }}
            />
            {address !== "" ? (
              <Button
                onClick={() => {
                  setItem({ address: "", name: "Show All Pools" });
                  setAddress("");
                }}
                className={
                  address === ""
                    ? `${classes.hidden}`
                    : `${classes.listItemShow} `
                }
              >
                <span
                  style={{ textTransform: "capitalize", marginRight: "3px" }}
                >
                  Show{" "}
                </span>
                all pools
              </Button>
            ) : null}
          </Box>
          <Divider />
          <Box className={classes.listContainer}>
            <List>
              {data.length > 0 ? (
                data.map((val, index: number) => {
                  return (
                    <ListItem
                      key={index}
                      onClick={() => {
                        const { address, name } = val;
                        setItem({ address, name });
                        setAddress(val.address);
                      }}
                      className={
                        val.address === address
                          ? `${classes.listItem} ${classes.listItemActive}`
                          : `${classes.listItem}`
                      }
                    >
                      <ListItemAvatar
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: "0px 5px",
                        }}
                        className={classes.listItemAvatar}
                      >
                        <DoubleToken
                          token0Uri={val.logo1 ?? ""}
                          token1Uri={val.logo2 ?? ""}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{
                          secondary: classes.sec,
                          primary: classes.pri,
                        }}
                        primary={val.name}
                      />
                      <GreenInfo content={val.percent} />
                    </ListItem>
                  );
                })
              ) : (
                <Empty />
              )}
            </List>
          </Box>
        </Container>
      </TabPanel>
    </MuiDialog>
  );
};

export default Dialog;
