import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { MainHeader } from "../components";
import { HelloWorld, Main } from "../pages";

const redirectRoute = "/";

function Routes() {
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <BrowserRouter>
        <MainHeader bottom={sm_ ? true : false} />
        <Switch>
          <Route exact path="/" component={Main} />
          <Redirect to={redirectRoute} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
