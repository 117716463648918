import { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import React from "react";
import { useWeb3React } from "@web3-react/core";

import { WDialog } from "../../components";

import { Logo, LogoTick } from "../";
import useStyles from "./styles";
import { useLocation } from "react-router-dom";
import { updatePilotPrice } from "../pilotLogo";

import { PILOT_LOGO } from "../../config/Constants";
import { setInterval } from "timers";

interface IMainHeader {
  bottom?: boolean;
}

const MainHeader: React.FC<IMainHeader> = ({ bottom = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);

  const loc = useLocation();
  const stats = loc.pathname !== "/";

  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));

  const getPrice = async () => {
    const price = await updatePilotPrice();
    setPrice(price);
  };

  useEffect(() => {
    getPrice();
    setInterval(() => {
      getPrice();
    }, 30000);
  }, []);

  return (
    <>
      <WDialog open={open} close={() => setOpen(false)} />
      <AppBar
        className={bottom ? classes.goToBottom : classes.appBar}
        elevation={0}
        color={bottom ? "secondary" : "transparent"}
        position={bottom ? "fixed" : "static"}
      >
        <Container maxWidth="xl" className={classes.contentContainer}>
          <Toolbar
            className={bottom ? classes.toolBarAfter : classes.toolBarBefore}
          >
            {sm_ ? (
              <LogoTick
                className={xs_ ? classes.logoTick_xs : classes.logoTick}
              />
            ) : (
              <Logo className={classes.logo} />
            )}
            {!stats && (
              <Grid
                container
                alignItems="center"
                justify={!sm_ ? "flex-end" : undefined}
                direction={sm_ ? "row-reverse" : "row"}
                spacing={1}
              >
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item className={classes.connectBtn}>
                      <Link
                        href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x37c997b35c619c21323f3518b9357914e8b99525"
                        target="_blank"
                      >
                        <Grid container spacing={2}>
                          <Grid item style={{ marginTop: "4px" }}>
                            <img
                              src={PILOT_LOGO}
                              alt="logo"
                              width="25px"
                              height="25px"
                            />
                          </Grid>
                          <Grid
                            item
                            style={{
                              padding: 0,
                              marginTop: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 700,
                                color: `${theme.palette.primary.main}`,
                              }}
                            >
                              Pilot
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              fontWeight: 700,
                              marginTop: "5px",
                              color: "#fff",
                            }}
                          >
                            ${price > 0 ? price : null}
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default MainHeader;
