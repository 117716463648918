import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  programmerIcon: {
    width: 20,
    height: 20,
    margin: "0px 10px",
  },
  contentContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    fontSize: "1rem",
    borderRadius: theme.shape.borderRadius,
    marginTop: `${theme.spacing(2)}px`,
    boxShadow: "0px 4px 8px rgb(27 27 27 / 16%)",
    backdropFilter: "blur(20px)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      borderRadius: "0",
    },
  },
  appBar: {
    marginBottom: "30px",
    padding: "0 35px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "25px",
    },
  },
  goToBottom: {
    top: "auto",
    bottom: 0,
  },
  toolBarBefore: {
    padding: "0px 20px",
    minHeight: "50px",
  },
  toolBarAfter: {
    padding: 0,
  },
  logo: {
    width: 170,
    height: 25,
  },
  logoTick_xs: {
    width: 45,
    height: 20,
  },
  logoTick: {
    width: 75,
    height: 30,
  },
  iconBtn: {
    position: "absolute",
    left: "-25px",
    top: "-4px",
  },
  tabValue: {
    marginLeft: "25px",
    "& .MuiTab-root": {
      minWidth: "120px",
    },
    "& .MuiTab-wrapper": {
      fontSize: "11px",
    },
  },
  connectBtn: {
    margin: "0 10px",
    maxHeight: "45px",
    "& .MuiLink-underlineHover": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
