import React from "react";
import { IconButton } from "@material-ui/core";

import useStyles from "./styles";

import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SettingsIcon from "@material-ui/icons/Settings";

interface IButton {
  onClick: (value: any) => any;
}

export const CloseButton: React.FC<IButton | any> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  );
};

export const BackButton: React.FC<IButton | any> = ({ onClick = () => {} }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};

export const SettingsButton: React.FC<IButton | any> = ({
  onClick = () => {},
  ...rest
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} {...rest} className={classes.closeButton}>
      <SettingsIcon />
    </IconButton>
  );
};
